import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import UserDashboard from '../components/UserDashboard.vue';
import GeneratedFiles from '../views/GeneratedFiles.vue';
import MyData from '../views/MyData.vue';
import CreateDocument from '../views/CreateDocument.vue';
import PassportsAndFiles from '../views/PassportsAndFiles.vue'; // Nowy widok wygenerowanych paszportów
import PassportForm from '@/views/PassportForm.vue'; //Nowy widok dla generowania paszportów
import RecipientPassAndFiles from '@/views/RecipientPassAndFiles.vue';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: UserLogin,
        meta: {
            title: 'Witaj na stronie logowania'
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: UserDashboard,
        children: [
            {
                path: 'generated-files',
                name: 'generated-files',
                component: GeneratedFiles,
                meta: { title: 'Wygenerowane Specyfikacje transportowe.', roles: ['sadmin', 'user'] } // Dostępne dla sadmin i user
            },
            {
                path: 'my-data',
                name: 'my-data',
                component: MyData,
                meta: {title: 'Moje dane', roles: ['sadmin', 'user', 'userpassport','recipient'] } // Dostępne dla sadmin i user
            },
            {
                path: 'create-document',
                name: 'create-document',
                component: CreateDocument,
                meta: {title: 'Utwórz dokument', roles: ['sadmin', 'user', 'userpassport','recipient'] } // Dostępne dla sadmin i user
            },
            {
                path: '/passport-form/:documentId',
                name: 'PassportForm',
                component: PassportForm,
                props: true,
                meta: {title: 'Tworzenie paszportu', roles: ['sadmin', 'userpassport', 'recipient'] }
            },
            {
                path: 'generated-passports',
                name: 'generated-passports',
                component: PassportsAndFiles,
                meta: {title: 'Wygenerowane paszporty', roles: ['sadmin', 'userpassport'] } // Dostępne tylko dla sadmin i userpassport
            },
            {
                path: 'generated-passports-recipient',
                name: 'generated-passports-recipient',
                component: RecipientPassAndFiles,
                meta: {title: 'Wygenerowane paszporty', roles: ['sadmin', 'recipient'] } // Dostępne tylko dla sadmin i odbiorcy
            }
        ]
    },
    {
        path: '/',
        redirect: '/login'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Generator dokumentów';
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    const user = JSON.parse(localStorage.getItem('user'));
    if (user && to.meta.roles && !to.meta.roles.includes(user.type)) {
        return next('/login'); // lub przekierowanie na inną stronę błędu, jeśli użytkownik nie ma dostępu
    }

    next();
});

export default router;
