<template>
  <div>
    <v-card-title>Wygenerowane Specyfikacje transportowe</v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="documents"
          class="elevation-1"
          :items-per-page="10"
          :sort-by="['timestamp']"
          :sort-desc="[true]"
      >
        <template v-slot:[`item.index`]="{ item }">
          {{ item.index }}
        </template>
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ formatDateTime(item.timestamp) }}
        </template>
        <template v-slot:[`item.count`]="{ item }">
          {{ item.count }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type }}
        </template>
        <template v-slot:[`item.date_of_departure`]="{ item }">
          {{ formatDate(item.date_of_departure) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon @click="downloadDocument(item)">mdi-download</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';
import apiClient from "@/api/apiClient";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'GeneratedFiles',
  data() {
    return {
      documents: [],
      headers: [
        { title: 'Liczba porządkowa', key: 'index' },
        { title: 'Data i godzina utworzenia', key: 'timestamp' },
        { title: 'Liczba sztuk', key: 'count' },
        { title: 'Typ', key: 'type' },
        { title: 'Data wyjazdu', key: 'date_of_departure' },
        { title: 'Akcja', key: 'action' },
      ],
    };
  },
  methods: {
    formatDateTime(dateString) {
      if (!dateString) return '';
      return new Date(dateString).toLocaleString('pl-PL');
    },
    formatDate(dateString) {
      if (!dateString) return '';
      return new Date(dateString).toLocaleDateString('pl-PL');
    },
    numberToWords(num) {
      const words = [
        'zero', 'jeden', 'dwa', 'trzy', 'cztery', 'pięć', 'sześć',
        'siedem', 'osiem', 'dziewięć', 'dziesięć', 'jedenaście', 'dwanaście',
        'trzynaście', 'czternaście', 'piętnaście', 'szesnaście', 'siedemnaście',
        'osiemnaście', 'dziewiętnaście',
      ];
      const tens = ['', '', 'dwadzieścia', 'trzydzieści', 'czterdzieści', 'pięćdziesiąt', 'sześćdziesiąt', 'siedemdziesiąt', 'osiemdziesiąt', 'dziewięćdziesiąt'];
      const hundreds = ['', 'sto', 'dwieście', 'trzysta', 'czterysta', 'pięćset', 'sześćset', 'siedemset', 'osiemset', 'dziewięćset'];

      if (num < 20) return words[num];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 ? ' ' + words[num % 10] : '');
      if (num < 1000) return hundreds[Math.floor(num / 100)] + (num % 100 ? ' ' + this.numberToWords(num % 100) : '');
      return num.toString();
    },
    generateBarcode(data) {
      const validData = data.toUpperCase().replace(/[^A-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_]/g, '');
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, validData, {
        format: 'CODE128A',
        displayValue: false,
      });
      return canvas.toDataURL('image/png');
    },
    async downloadDocument(item) {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');

      let headlineData = {
        full_name: 'Nieznany',
        address: 'Nieznany',
        NIP: 'Nieznany',
        herd_headquarters_number: 'Nieznany',
      };

      let recipientData = {
        name: 'Nieznany',
        address: 'Nieznany',
        herd_headquarters_number: 'Nieznany',
      };

      // Pobieranie danych headline na podstawie ID użytkownika
      try {
        const headlineResponse = await apiClient.get(`/api/headline/${user.id}`);
        headlineData = headlineResponse.data;
      } catch (error) {
        console.error('Błąd podczas pobierania danych headline:', error);
      }

      // Pobieranie danych odbiorcy
      if (item.recipient) {
        try {
          const recipientResponse = await apiClient.get(`/api/recipient/${item.recipient}`);
          recipientData = recipientResponse.data;
        } catch (error) {
          console.error('Błąd podczas pobierania danych odbiorcy:', error);
        }
      }

      // Pobieranie danych sztuk na podstawie ID dokumentu
      let documentData = [];
      try {
        const documentResponse = await apiClient.get(`/api/sztuki/${item.id}`);
        documentData = documentResponse.data;
      } catch (error) {
        console.error('Błąd podczas pobierania danych sztuk:', error);
      }

      let selectedCarrierNumber = 'Nieznany';
      try {
        const documentResponse = await apiClient.get(`/api/documents/${item.id}`);

        const documentData = documentResponse.data;

        // Sprawdzenie i przypisanie wartości selectedCarrierNumber
        if (documentData && documentData[0] && documentData[0].selected_carrier_number) {
          selectedCarrierNumber = documentData[0].selected_carrier_number;
        }
      } catch (error) {
        console.error('Błąd podczas pobierania danych dokumentu:', error);
      }

      let selectedHerdHeadquartersNumber = 'Nieznany';
      try {
        const documentResponse = await apiClient.get(`/api/documents/${item.id}`);

        const documentData = documentResponse.data;

        // Sprawdzenie i przypisanie wartości selectedCarrierNumber
        if (documentData && documentData[0] && documentData[0].selected_herd_headquarters_number) {
          selectedHerdHeadquartersNumber = documentData[0].selected_herd_headquarters_number;
        }
      } catch (error) {
        console.error('Błąd podczas pobierania danych dokumentu:', error);
      }


      // Konwersja liczby sztuk na słowa
      const countInWords = this.numberToWords(item.count);

      // Uzupełnienie tabeli
      const tableBody = [
        [
          { text: 'Lp', style: 'tableHeader', alignment: 'center', fontSize: 8 },
          { text: 'Rodzaj żywca', style: 'tableHeader', alignment: 'center', fontSize: 8 },
          { text: 'Numer siedziby stada', style: 'tableHeader', alignment: 'center', fontSize: 8 },
          { text: 'Data urodzenia', style: 'tableHeader', alignment: 'center', fontSize: 8 },
          { text: 'Numer kolczyka', style: 'tableHeader', alignment: 'center', fontSize: 8 },
          { text: 'Nazwisko i imię rolnika', style: 'tableHeader', alignment: 'center', fontSize: 8 },
        ],
      ];

      if (documentData && documentData.length) {
        documentData.forEach((animal, index) => {
          tableBody.push([
            { text: index + 1, alignment: 'center', fontSize: 8 },
            { text: animal.specific_type, alignment: 'center', fontSize: 8 },
            { text: animal.farmer_registered_number, alignment: 'center', fontSize: 8 },
            { text: this.formatDate(animal.date_of_birth), alignment: 'center', fontSize: 8 },
            { text: animal.earring_number, alignment: 'center', fontSize: 8 },
            { text: `${animal.farmer_name} ${animal.farmer_surname}`, alignment: 'center', fontSize: 8 },
          ]);

          tableBody.push([
            { colSpan: 6, image: this.generateBarcode(animal.earring_number), alignment: 'center', fit: [500, 40] },
            {}, {}, {}, {}, {},
          ]);
        });
      }

      const docDefinition = {
        content: [
          {
            text: `${item.place}, ${this.formatDate(item.date_of_departure)}`,
            fontSize: 10,
            alignment: 'right',
            margin: [0, 0, 0, 20],
          },
          {
            text: headlineData.full_name,
            fontSize: 12,
            margin: [0, 0, 0, 5],
          },
          {
            text: headlineData.address,
            fontSize: 12,
            margin: [0, 0, 0, 5],
          },
          {
            text: `NIP: ${headlineData.NIP}`,
            fontSize: 12,
            margin: [0, 0, 0, 5],
          },
          {
            text: `Nr identyfikacyjny przewoźnika: ${selectedCarrierNumber}`,
            bold: true,
            margin: [0, 0, 0, 20],
          },
          {
            text: 'INFORMACJA DOTYCZĄCA PRZESYŁKI ZWIERZĄT',
            fontSize: 16,
            bold: true,
            alignment: 'center',
            margin: [0, 20, 0, 20],
          },
          {
            text: 'I. Identyfikacja zwierząt:',
            fontSize: 12,
            bold: true,
            alignment: 'left',
            margin: [0, 20, 0, 5],
          },
          {
            text: `1. Gatunek zwierząt: ${item.type}`,
            fontSize: 10,
            margin: [10, 0, 0, 5],
          },
          {
            text: `2. Ilość zwierząt (słownie): ${countInWords}`,
            fontSize: 10,
            alignment: 'left',
            margin: [10, 0, 0, 5],
          },
          {
            text: 'II. Pochodzenie i przeznaczenie zwierząt:',
            fontSize: 12,
            bold: true,
            alignment: 'left',
            margin: [0, 20, 0, 5],
          },
          {
            text: `1. Nazwa i adres właściciela zwierząt (dealera): ${headlineData.full_name}, ${headlineData.address}`,
            fontSize: 10,
            margin: [10, 0, 0, 10],
          },
          {
            text: `2. Numer siedziby stada dostawcy (dealera): ${selectedHerdHeadquartersNumber}`,
            fontSize: 10,
            alignment: 'left',
            margin: [10, 0, 0, 5],
          },
          {
            text: `3. Miejsce, data i godzina wyjazdu: ${item.place}, ${this.formatDate(item.date_of_departure)}, ${item.time_of_departure}`,
            fontSize: 10,
            margin: [10, 0, 0, 5],
          },
          {
            text: `4. Przewidywany czas transportu: ${item.transport_time !== null ? item.transport_time : '......'} godzin/y`,
            fontSize: 10,
            margin: [10, 0, 0, 5],
          },
          {
            text: `5. Nazwa i adres odbiorcy: ${recipientData.name}, ${recipientData.address} / Nr siedziby stada: ${recipientData.herd_headquarters_number}`,
            fontSize: 10,
            margin: [10, 0, 0, 5],
          },
          {
            text: `III. Nr kolczyków`,
            bold: true,
            fontSize: 12,
            margin: [0, 20, 0, 5],
          },
          {
            style: 'tableExample',
            table: {
              headerRows: 1,
              widths: [15, '*', 150, '*', 100, 100],
              body: tableBody,
            },
            layout: 'lightHorizontalLines',
            margin: [0, 10, 0, 0],
            pageBreakBefore: function (currentNode) {
              // Ensure that the entire animal data (both rows) fits on the same page
              const rowsInNode = currentNode.table.body.length;
              return currentNode.startPosition.top + (rowsInNode * 40) > (currentNode.pageSize.height - 80);
            },
          },
            {
            text: 'Numer/y rejestracyjny/e auta:',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 5],
            },
            {
            text: `Numer ciągnika siodłowego / ciężarówki: ${item.truck_registration_number || 'Nieznany'}`,
            fontSize: 10,
            margin: [0, 0, 0, 5],
            },
            {
            text: `Numer naczepy (w przypadku ciągnika siodłowego): ${item.trailer_registration_number || 'Nieznany'}`,
            fontSize: 10,
            margin: [0, 0, 0, 5],
            },
            {
            text: `Dostawca/dealer: ${headlineData.full_name}`,
            fontSize: 10,
            margin: [0, 0, 0, 5],
            },
            {
            text: '_______________________________________                            _______________________________________',
            fontSize: 10,
            alignment: 'center',
            margin: [0, 20, 0, 0],
            },
            {
            text: '/podpis przewoźnika/                                                  /pieczątka i podpis/',
            fontSize: 10,
            alignment: 'center',
            }
          ],
      };

      pdfMake.createPdf(docDefinition).download(`dokument_${item.index}.pdf`);
    },
    editDocument(item) {
      console.log('Edytowanie dokumentu:', item);
    },
  },
  async mounted() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    if (user && user.id) {
      try {
        const response = await apiClient.get('/api/documents', {
          params: { user_id: user.id },
          headers: { Authorization: `${token}` },
        });
        this.documents = response.data.map((doc, index) => ({
          ...doc,
          index: index + 1,
        }));
      } catch (error) {
        console.error('Błąd podczas pobierania dokumentów:', error);
      }
    } else {
      console.error('Nie znaleziono użytkownika w localStorage');
    }
  },
};
</script>

<style scoped>
.v-data-table-header th {
  font-weight: bold;
  text-align: left;
}
.v-icon {
  cursor: pointer;
  margin: 0 5px;
}
</style>
