import axios from 'axios';

// Tworzymy instancję Axios z dynamicznym `baseURL`
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Adres API pobrany z plików .env
    headers: {
        'Content-Type': 'application/json', // Możesz dostosować nagłówki do swoich potrzeb
    },
});

// Opcjonalne: Dodaj interceptory, aby np. automatycznie dodawać token autoryzacyjny
apiClient.interceptors.request.use(
    (config) => {
        // Pobierz token z lokalnego magazynu (localStorage, możesz to zmienić na inny mechanizm)
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Eksportujemy instancję klienta API
export default apiClient;