<template>
  <div>
    <v-card-title>Formularz Paszportu</v-card-title>
    <v-card-text>
      <v-container class="mt-6">
        <v-card-title>Ustawienia globalne</v-card-title>
        <v-row>
          <v-col cols="12" sm="3">
            <!-- Imię rolnika -->
            <v-select
                v-model="globalGender"
                :items="genderStructures"
                item-title="name"
                item-value="value"
                label="Płeć zwierzęcia"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field v-model="globalBreed" label="Rasa"></v-text-field>
          </v-col>
        <v-col cols="12" sm="3">
          <!-- Zaznacz wszystkie sztuki -->
          <v-checkbox
              v-model="selectAllAnimals"
              label="Zaznacz wszystkie sztuki bydła"
              @change="toggleSelectAll"
          ></v-checkbox>
        </v-col>
        <v-col cols="6" sm="3">
          <!-- Przycisk zastosowania danych -->
          <v-btn @click="applyGlobalSettings">Zastosuj dla wybranych</v-btn>
        </v-col>
        </v-row>
      </v-container>
      <div v-for="(animal, index) in animals" :key="index" class="animal-form">
        <h5>Sztuka {{ index + 1 }} - Numer kolczyka: {{ animal.earring_number }}</h5>
        <v-row>
          <v-col cols="1">
            <v-checkbox v-model="animal.selected"></v-checkbox>
          </v-col>
          <!-- Numer paszportu jako OTP Input -->
          <v-col cols="12" md="2">
            <v-label class="label">Numer paszportu</v-label>
            <v-otp-input
                class="otp"
                placeholder="?"
                v-model="animal.passport_number"
                length="10"
                color="primary"
                disabled
                variant="solo-filled"
                readonly
            ></v-otp-input>
          </v-col>

          <!-- Numer identyfikacyjny matki jako OTP Input -->
          <v-col cols="12" md="3">
            <v-label class="label">Numer identyfikacyjny matki</v-label>
            <v-otp-input
                class="otp"
                placeholder="?"
                v-model="animal.mothers_id_number"
                length="14"
                color="primary"
                disabled
                variant="solo-filled"
                readonly
            ></v-otp-input>
          </v-col>

          <!-- Wybór płci -->
          <v-col cols="12" md="2">
            <v-select
                v-model="animal.gender"
                :items="genderStructures"
                item-title="name"
                item-value="value"
                label="Płeć"
            ></v-select>
          </v-col>

          <!-- Rasa zwierzęcia -->
          <v-col cols="12" md="2">
            <v-text-field
                label="Rasa zwierzęcia"
                v-model="animal.breed"
            ></v-text-field>
          </v-col>

          <!-- Z Unii Europejskiej -->
          <v-col cols="12" md="2">
            <v-text-field
                label="Z Unii Europejskiej (opcjonalne)"
                v-model="animal.from_eu"
            ></v-text-field>
          </v-col>

          <!-- Spoza Unii Europejskiej -->
          <v-col cols="12" md="2">
            <v-text-field
                label="Spoza Unii Europejskiej (opcjonalne)"
                v-model="animal.from_non_eu"
            ></v-text-field>
          </v-col>

          <!-- Data kupna -->
          <v-col cols="12" md="2">
            <v-text-field
                v-model="animal.purchase_date"
                :value="formattedDate(animal.purchase_date)"
                label="Data kupna (opcjonalne)"
                prepend-icon="mdi-calendar"
                readonly
                @click="openDateMenu('purchase_date', index)"
            ></v-text-field>
            <v-dialog
                v-model="animal.purchase_date_menu"
                max-width="290"
                class="custom-dialog"
            >
              <v-date-picker
                  v-model="animal.purchase_date"
                  @input="updateDate('purchase_date', index)"
                  color="primary"
              ></v-date-picker>
            </v-dialog>
          </v-col>

          <!-- Data przywozu -->
          <v-col cols="12" md="2">
            <v-text-field
                v-model="animal.import_date"
                :value="formattedDate(animal.import_date)"
                label="Data przywozu (opcjonalne)"
                prepend-icon="mdi-calendar"
                readonly
                @click="openDateMenu('import_date', index)"
            ></v-text-field>
            <v-dialog
                v-model="animal.import_date_menu"
                max-width="290"
                class="custom-dialog"
            >
              <v-date-picker
                  v-model="animal.import_date"
                  @input="updateDate('import_date', index)"
                  color="primary"
              ></v-date-picker>
            </v-dialog>
          </v-col>

          <!-- Numer identyfikacyjny zwierzęcia -->
          <v-col cols="12" md="2">
            <v-text-field
                label="Nr identyfikacyjny zwierzęcia (opcjonalne)"
                v-model="animal.animal_id"
            ></v-text-field>
          </v-col>

          <!-- Data uboju lub upadku -->
          <v-col cols="12" md="2">
            <v-text-field
                v-model="animal.death_date"
                :value="formattedDate(animal.death_date)"
                label="Data uboju lub upadku"
                prepend-icon="mdi-calendar"
                readonly
                @click="openDateMenu('death_date', index)"
            ></v-text-field>
            <v-dialog
                v-model="animal.death_date_menu"
                max-width="290"
                class="custom-dialog"
            >
              <v-date-picker
                  v-model="animal.death_date"
                  @input="updateDate('death_date', index)"
                  color="primary"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <!-- Adres -->
          <v-col cols="12" md="6">
            <v-text-field
                label="Adres rolnika"
                v-model="animal.farmer_address"
            ></v-text-field>
          </v-col>

          <!-- Kod pocztowy -->
          <v-col cols="12" md="2">
            <v-text-field
                label="Kod pocztowy rolnika"
                v-model="animal.postcode"
            ></v-text-field>
          </v-col>

          <!-- Miasto -->
          <v-col cols="12" md="2">
            <v-text-field
                label="Miejscowość rolnika"
                v-model="animal.city"
            ></v-text-field>
          </v-col>

          <!-- Inne adnotacje -->
          <v-col cols="12">
            <v-textarea
                label="Inne adnotacje"
                v-model="animal.notes"
                rows="3"
                auto-grow
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="savePassportData" @keydown="blockEnter">Zapisz</v-btn>
      <v-btn color="secondary" @click="cancel" @keydown="blockEnter">Anuluj</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from 'axios';
import apiClient from "@/api/apiClient";

export default {
  data() {
    return {
      globalGender: '',
      globalBreed: '',
      selectAllAnimals: false,
      existingMotherIds: new Set(),
      animals: [],
      genderStructures: [
        { name: "Samica XX", value: "Samica XX" },
        { name: "Samiec XY", value: "Samiec XY" }
      ]
    };
  },
  async created() {
    const token = localStorage.getItem('token');
    const documentId = this.$route.params.documentId;
    try {
      const response = await apiClient.get(`/api/sztuki/${documentId}`);
      this.animals = response.data.map(animal => ({
        ...animal,
        passport_number: this.generatePassportNumber(),
        mothers_id_number: this.generateUniqueMotherId(animal.earring_number),
        gender: null,
        breed: '',
        from_eu: '',
        purchase_date: null,
        from_non_eu: '',
        import_date: null,
        animal_id: '',
        death_date: null,
        notes: '',
        purchase_date_menu: false,
        import_date_menu: false,
        death_date_menu: false,
        farmer_address: '',
        postcode: '',
        city: '',
      }));
    } catch (error) {
      console.error('Błąd podczas pobierania danych sztuk:', error);
    }
  },
  watch: {
    // Obserwuj zmiany w polu `selected` w każdym obiekcie tablicy `animals`
    animals: {
      handler(newAnimals) {
        // Jeśli wszystkie zwierzęta są zaznaczone, ustaw `selectAllAnimals` na true
        // W przeciwnym razie ustaw na false
        this.selectAllAnimals = newAnimals.every(animal => animal.selected);
      },
      deep: true, // Obserwuj głębokie zmiany (zmiany wewnątrz obiektów tablicy)
    },
  },
  methods: {
    handleEnterKey(event) {
      if (event.key === 'Enter') {
        // Pobierz element, który obecnie ma fokus
        const activeElement = document.activeElement;

        // Jeśli fokus jest na przycisku lub w polu tekstowym, nic nie rób
        if (activeElement.tagName === 'TEXTAREA' || activeElement.tagName === 'BUTTON') {
          return;
        }

        // Zablokuj domyślne zachowanie Enter (zapobiegnie wysyłaniu formularza)
        event.preventDefault();
      }
    },
    blockEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault(); // Dodatkowe zabezpieczenie dla przycisku
      }
    },
    toggleSelectAll() {
      // Zaznacz lub odznacz wszystkie pozycje w zależności od stanu selectAllAnimals
      const isSelected = this.selectAllAnimals;
      this.animals.forEach(animal => {
        animal.selected = isSelected;
      });
    },
    updateSelectAllStatus() {
      // Jeśli wszystkie są zaznaczone, ustaw selectAllAnimals na true
      // W przeciwnym razie ustaw na false
      this.selectAllAnimals = this.animals.every(animal => animal.selected);
    },
    applyGlobalSettings() {
      // Zastosuj globalne ustawienia tylko dla zaznaczonych pozycji
      this.animals.forEach(animal => {
        if (animal.selected) {
          if (this.globalGender) animal.gender = this.globalGender;
          if (this.globalBreed) animal.breed = this.globalBreed;
        }
      });
    },
    generatePassportNumber() {
      const randomNumbers = Math.floor(10000000 + Math.random() * 90000000);
      return `AD${randomNumbers}`;
    },
    generateUniqueMotherId(earringNumber) {
      // Mapowanie prefiksów dla różnych krajów
      const prefixFormats = {
        'PL': 'PL005',  // Polska
        'LT': 'LT00000', // Litwa
        // Możesz dodać więcej krajów i ich prefiksy
      };

      // Pobieranie dwóch pierwszych znaków numeru zwierzęcia
      const countryCode = earringNumber.slice(0, 2).toUpperCase();
      const prefix = prefixFormats[countryCode] || `${countryCode}00`; // Domyślny prefiks dla nieznanego kodu kraju

      // Obliczamy, ile cyfr musimy dodać, aby uzyskać 14 znaków
      const remainingLength = 14 - prefix.length;
      let uniqueId;

      do {
        // Generowanie losowych cyfr w odpowiedniej długości
        const randomNumbers = Math.floor(Math.random() * 10 ** remainingLength).toString().padStart(remainingLength, '0');
        uniqueId = `${prefix}${randomNumbers}`; // Łączenie prefiksu z losowymi cyframi
      } while (this.existingMotherIds.has(uniqueId)); // Sprawdzanie unikalności

      this.existingMotherIds.add(uniqueId); // Dodawanie do zbioru unikalnych numerów
      return uniqueId;
    },
    formattedDate(date) {
      return date ? new Date(date).toLocaleDateString() : '';
    },
    openDateMenu(field, index) {
      this.animals[index][`${field}_menu`] = true;
    },
    updateDate(field, index) {
      this.animals[index][`${field}_menu`] = false;
    },
    async savePassportData() {
      const documentId = this.$route.params.documentId;
      const token = localStorage.getItem('token');
      const passportsData = this.animals.map(animal => ({
        doc: documentId,
        animal_id: animal.id, // używamy tego jako id_sztuki
        passport_number: animal.passport_number,
        gender: animal.gender,
        breed: animal.breed,
        mothers_id_number: animal.mothers_id_number,
        from_eu: animal.from_eu,
        purchase_date: animal.purchase_date,
        from_non_eu: animal.from_non_eu,
        import_date: animal.import_date,
        animal_id_number: animal.animal_id,
        death_date: animal.death_date,
        notes: animal.notes,
        farmer_registered_number: animal.farmer_registered_number, // Dodane, jeśli jest potrzebne
        farmer_address: animal.farmer_address,
        postcode: animal.postcode,
        city: animal.city
      }));

      try {
        const response = await apiClient.post('/api/passports', { passports: passportsData });
        await apiClient.patch(`/api/documents/${documentId}/update-formula`, { formula: 1 });
        console.log('Paszporty zapisane:', response.data);
        this.$router.push({ name: 'generated-passports' });
      } catch (error) {
        console.error('Błąd podczas zapisywania paszportów:', error);
      }
    },
    cancel() {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && ['sadmin', 'userpassport'].includes(user.type)) {
        // Jeśli użytkownik ma odpowiednią rolę, przekieruj do 'generated-passports'
        this.$router.push({ name: 'generated-passports' });
      } else {
        console.warn('Brak dostępu do "generated-passports".');
        // Opcjonalnie: Powrót do poprzedniego widoku
        this.$router.go(-1);
      }
    }
  },
};
</script>

<style scoped>
.animal-form {
  margin-bottom: 25px;
}

.otp {
  bottom: 10px;
}

.v-row {
  margin-bottom: 15px;
}

.label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.custom-dialog .v-dialog__content {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
