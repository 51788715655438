<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>Miło Cię widzieć - {{ user.user_name }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app expand-on-hover rail>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ user.user_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
            v-if="userType === 'sadmin' || userType === 'user'"
            to="/dashboard/generated-files"
        >
          <v-list-item-title>Wygenerowane Specyfikacje transportowe</v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="userType === 'sadmin' || userType === 'user' || userType === 'userpassport'"
            to="/dashboard/create-document"
        >
          <v-list-item-title>Utwórz dokument</v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="userType === 'sadmin' || userType === 'userpassport'"
            to="/dashboard/generated-passports"
        >
          <v-list-item-title>Paszporty i specyfikacje transportu</v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="userType === 'sadmin' || userType === 'recipient'"
            to="/dashboard/generated-passports-recipient"
        >

          <v-list-item-title>Paszporty i specyfikacje transportu</v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="userType === 'sadmin' || userType === 'user' || userType === 'userpassport'"
            to="/dashboard/my-data"
        >

          <v-list-item-title>Moje dane</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>

      <v-list-item @click="logout" class="logout-btn">
        <v-list-item-action>
          <v-icon>mdi-logout</v-icon><v-list-item-title>Wyloguj</v-list-item-title>
        </v-list-item-action>
      </v-list-item>
    </v-navigation-drawer>

    <v-main class="d-flex justify-center" style="min-height: 300px;">
      <v-container>
        <v-card class="pa-4">
          <router-view></router-view>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'UserDashboard',
  data() {
    return {
      user: {},
    };
  },

  computed: {
    userType() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.type : '';
      }
    },

  mounted() {
    const userData = localStorage.getItem('user');
    if (userData) {
      try {
        this.user = JSON.parse(userData);
      } catch (e) {
        console.error("Error parsing user data from localStorage", e);
        this.user = {};
      }
    }
  },
  methods: {
    navigateTo(view) {
      this.$router.push({ name: view });
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  background-color: #fff;
}
.logout-btn {
  width: 100%;
}
</style>
